import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { MenuIcon } from "../../assets/Icons";
import {
  TwitterIcon,
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
} from "../../assets/Icons";
const Header = ({ openSidebar, setOpenSidebar }) => {
  const dispatch = useDispatch();

  const userLogout = () => {
    dispatch(logout("admin-token"));
  };
  const socialList = [
    {
      label: "Twitter",
      Icon: <TwitterIcon />,
      url: "https://twitter.com/Cryptocolorco",
    },
    {
      label: "Discord",
      Icon: <DiscordIcon />,
      url: "https://discord.gg/H9RMsY9A",
    },
    {
      label: "Facebook",
      Icon: <FacebookIcon />,
      url: " https://www.facebook.com/CryptoColorCollection",
    },
    {
      label: "Instagram",
      Icon: <InstagramIcon />,
      url: "https://www.instagram.com/cryptocolorcollection/",
    },
    {
      label: "Reddit",
      Icon: <DiscordIcon />,
      url: " https://www.reddit.com/r/CryptoColorCollection/",
    },
  ];
  return (
    <div className="header-camp flex">
      <div className="wrapWidth wrap flex items-center">
        <div className="left flex items-center">
          <Link to="/" className="logo-img flex items-center justify-center">
            <img src="../images/logo.svg" className="logo" />
          </Link>
        </div>
        <div className="center flex items-center justify-center">
          <div className="menu-list flex items-center justify-center">
          <a href="#home" className="menu-item">
              Home
            </a>
            <a href="#about" className="menu-item">
              About us
            </a>
            <a href="#roadmap" className="menu-item">
              Roadmap
            </a>
            <a href="#faq" className="menu-item">
              Faq
            </a>

          </div>

        </div>
        <div className="right flex items-center justify-end">
          <button className="btn-signup button"><a href="https://mint.cryptocolorcollection.com/" > Mint Now</a></button>
        </div>

                <div className="socials flex items-center justify-center">
            {socialList.map((item, index) => (
              <a
                href={item.url}
                target="_blank"
                className="icon flex items-center justify-center"
              >
                {item.Icon}
              </a>
            ))}
          </div>

        
        {/* <div
          className="menu-icon flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setOpenSidebar(!openSidebar);
          }}
        >
          <MenuIcon />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
