import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="#fff"
        d="M19.002 6.068a1.307 1.307 0 100 2.614 1.307 1.307 0 000-2.614zm5.007 2.635a8.26 8.26 0 00-.501-2.645 5.378 5.378 0 00-1.263-1.927 5.116 5.116 0 00-1.926-1.252 7.947 7.947 0 00-2.646-.512c-1.153-.065-1.523-.065-4.484-.065-2.961 0-3.331 0-4.485.065a7.946 7.946 0 00-2.645.512 5.203 5.203 0 00-1.927 1.252A5.116 5.116 0 002.88 6.058a7.946 7.946 0 00-.511 2.645c-.066 1.154-.066 1.524-.066 4.485 0 2.96 0 3.33.066 4.484.018.905.191 1.8.511 2.645a5.116 5.116 0 001.252 1.927c.54.556 1.2.983 1.927 1.252.846.32 1.74.493 2.645.512 1.154.065 1.524.065 4.485.065 2.96 0 3.33 0 4.484-.065a7.945 7.945 0 002.646-.512 5.117 5.117 0 001.926-1.252 5.28 5.28 0 001.263-1.927 8.26 8.26 0 00.5-2.645c0-1.154.066-1.524.066-4.485 0-2.96 0-3.33-.065-4.484zm-1.96 8.839a6.11 6.11 0 01-.37 2.024 3.332 3.332 0 01-.816 1.252c-.353.36-.78.638-1.252.817a6.107 6.107 0 01-2.025.37c-1.088.054-1.491.065-4.354.065s-3.265 0-4.354-.065a6.236 6.236 0 01-2.112-.327 3.558 3.558 0 01-1.197-.816 3.266 3.266 0 01-.806-1.252 6.03 6.03 0 01-.435-2.068c0-1.089-.065-1.492-.065-4.355 0-2.862 0-3.265.065-4.354a6.03 6.03 0 01.38-2.068c.193-.46.487-.869.86-1.197.332-.375.74-.672 1.198-.871a6.237 6.237 0 012.068-.37c1.089 0 1.492-.066 4.355-.066 2.863 0 3.265 0 4.354.066a6.107 6.107 0 012.024.37c.495.183.94.482 1.296.87.357.335.635.744.816 1.198.242.663.367 1.363.37 2.068.055 1.089.066 1.492.066 4.354 0 2.863-.011 3.266-.066 4.355zm-8.86-9.939a5.584 5.584 0 105.595 5.585 5.572 5.572 0 00-5.595-5.585zm0 9.21a3.625 3.625 0 110-7.25 3.625 3.625 0 010 7.25z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
